<template>
    <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left">

        <!-- general tab -->



        <!-- info -->
        <b-tab>

            <!-- title -->
            <template #title>
                <feather-icon icon="InfoIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">{{ $t('Information') }}</span>
            </template>

            <user-profile-info />

            <!-- <account-setting-information
          v-if="options.info"
          :information-data="options.info"
        /> -->
        </b-tab>


        <!-- change password tab -->
        <b-tab>

            <!-- title -->
            <template #title>
                <feather-icon icon="LockIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">{{ $t('Change Password') }}</span>
            </template>

            <change-user-password />
        </b-tab>
        <!--/ change password tab -->


    </b-tabs>
</template>
  
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ChangeUserPassword from './changeUserPassword.vue'
import UserProfileInfo from './userProfileInfo.vue'

export default {
    components: {
        BTabs,
        BTab,
        ChangeUserPassword,
        UserProfileInfo,
    },
    data() {
        return {
            options: {},
        }
    },
    beforeCreate() {
        // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
    },
}
</script>
  