<template>
    <b-card>
        <!-- form -->
        <b-form>
            <b-row>
                <!-- username -->
                <b-col md="6">
                    <b-form-group label-for="username" :label="$t('Username')">
                        <b-form-input id="username" v-model="userInfo.username" disabled />
                    </b-form-group>
                </b-col>
                <!--/ username -->

                <!-- contactFirstName -->
                <b-col md="6">
                    <b-form-group label-for="FirstName" :label="$t('First Name')">
                        <b-form-input id="FirstName" v-model="userInfo.contactFirstName" disabled />
                    </b-form-group>
                </b-col>
                <!--/ contactFirstName -->


                <!-- Last Name -->
                <b-col md="6">
                    <b-form-group label-for="LastName" :label="$t('Last Name')">
                        <b-form-input id="LastName" v-model="userInfo.contactLastName" disabled />
                    </b-form-group>
                </b-col>
                <!--/ Last Name -->

                <!-- Email -->
                <b-col md="6">
                    <b-form-group label-for="Email" :label="$t('Email')">
                        <b-form-input id="Email" v-model="userInfo.contactEmail" disabled />
                    </b-form-group>
                </b-col>
                <!--/ Email -->

                <!-- PhoneNumber -->
                <b-col md="6">
                    <b-form-group label-for="phoneNumber" :label="$t('The mobile number of the company representative')">
                        <b-form-input id="phoneNumber" v-model="userInfo.phoneNumber" disabled />
                    </b-form-group>
                </b-col>
                <!--/ PhoneNumber -->


                <!-- TaxId -->
                <b-col md="6">
                    <b-form-group label-for="Email" :label="$t('taxNumber')">
                        <b-form-input id="Email" v-model="userInfo.taxId" disabled />
                    </b-form-group>
                </b-col>
                <!--/ TaxId -->


                <!-- companyName -->
                <b-col md="6">
                    <b-form-group label-for="companyName" :label="$t('Company Name')">
                        <b-form-input id="companyName" v-model="userInfo.companyName" disabled />
                    </b-form-group>
                </b-col>
                <!--/ companyName -->

                <!-- companyCommerceNumber
 -->
                <b-col md="6">
                    <b-form-group label-for="companyCommerceNumber" :label="$t('Commerce Number')">
                        <b-form-input id="companyCommerceNumber" v-model="userInfo.companyCommerceNumber" disabled />
                    </b-form-group>
                </b-col>
                <!--/ companyCommerceNumber
 -->
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-extraneous-dependencies

export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BFormTextarea,
    },
    directives: {
        Ripple,
    },

    mounted() {
        this.data = JSON.parse(localStorage.getItem('userInfo'))
        this.userInfo = this.data.partnerInfo.data
    },
    data() {
        return {
            data: '',
            userInfo: ''

        }
    },
    methods: {
        resetForm() {
            // this.localOptions = JSON.parse(JSON.stringify(this.informationData))
        },
    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
  