<template>
    <b-card>
        <!-- form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- password -->
                    <b-col cols="6">
                        <b-form-group :label="$t('Password')" label-for="password">
                            <validation-provider #default="{ errors }" :name="$t('Password')" vid="Password"
                                rules="required|min:8|alpha_dash">
                                <b-form-input id="password" v-model="password" :state="errors.length > 0 ? false : null"
                                    type="password" :placeholder="$t('Password')" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- confirm password -->
                    <b-col cols="6">
            <b-form-group
              :label="$t('Confirm Password')"
              label-for="confirmedPassword"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Confirm Password')"
                rules="required|confirmed:Password"
              >

                <b-form-input
                  id="confirmedPassword"
                  v-model="confirmedPassword"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  :placeholder="$t('Confirm Password')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
                    <!-- Must match the specified regular expression : ^([0-9]+)$ - numbers only -->
                    <b-col md="6">
                        <b-form-group>
                            <label>{{ $t('phoneNumber') }}</label>
                            <validation-provider #default="{ errors }" rules="required|min:9|max:10|integer"
                                :name="$t('phoneNumber')">
                                <b-form-input v-model="phoneNumber" :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('phoneNumber')" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- reset button -->
                    <b-col cols="12">
                        <b-button variant="primary" type="submit" @click.prevent="validationForm">
                            {{ $t('changePassword') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      phoneNumber: '',
      password: '',
      confirmedPassword: '',
      required,
      min,
      confirmed,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    showToast(data, state) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data?.data?.name || data.name,
          icon: 'BellIcon',
          text: data?.data?.detail || data.message,
          variant: state,
        },
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
                    this.changePassword()
        }
      })
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changePassword() {
      useJwt.put('/partner/api/domain/partners/password', {
        newPassword: this.confirmedPassword,
        phone: this.phoneNumber,
      }).then(
        (data) => {
          if(data.status === 200) {
            const data = {
                name: this.$t('SuccessRequest'),
                message: this.$t('SuccessRequestMessage'),
            }
            this.showToast(data, 'success')
          }
        },
        (data) => {
        this.showToast(data, 'danger')
        },
      )
    },
  },
}
</script>
